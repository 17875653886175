import axios from "./index";

class SignupApi {
    static registerTrainerUser = (trainerId,data) => {
        return axios.post(`${base}/trainers/${trainerId}`, data);
    };

    static registerManagerUser = (instituteId, data) => {
        return axios.post(`${base}/managers/${instituteId}`, data);
    };

    static registerTeacherUser = (teacherId, data) => {
        return axios.post(`${base}/teachers/${teacherId}`, data);
    };

    static registerStudentUser = (studentId,data) => {
        return axios.post(`${base}/students/${studentId}`, data);
    };

}

let base = "/api/signup";

export default SignupApi;
