import axios from "./index";

class SearchApi {
    static getSearch = (query) => {
        return axios.get(`${base}?query=${query}`);
    };
}

let base = "/api/search";

export default SearchApi;
