import React, {useEffect, useState} from 'react';
import InstituteMeasureList from "../../component/institute-measure/InstituteMeasureList";
import InstitutesApi from "../../api/Institutes";
import InstituteMeasureApi from "../../api/InstituteMeasures";
import Institute from "../../component/institute/Institute";
import {Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography} from "@mui/material";
import {TotalSummary} from "../../types/TotalSummary";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListAlert from "../../component/ListAlert";
import DashboardApi from "../../api/Dashboards";
import {styled} from "@mui/styles";

const InstituteDashboard = () => {
    const [instituteMeasures, setInstituteMeasures] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [dashboards, setDashboards] = useState([]);

    const getInstitutes = () => {
        InstitutesApi.get().then(response => setInstitutes(response.data.data));
    }
    const getInstituteMeasures = () => {
        InstituteMeasureApi.get().then(response => setInstituteMeasures(response.data.data));
    }
    const getDashboards = () => {
        DashboardApi.get().then(response => setDashboards(response.data.data));
    }

    const listItems = institutes?.map((institute) =>
        <Institute key={institute.id} institute={institute} onChange={handleChangeInstituteMeasure} showActionMenu={true}/>
    );

    const DashboardPaper = styled(Paper)(({theme}) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        color: theme.palette.text.secondary,

        lineHeight: '60px',
    }));

    const dashboardItems = dashboards?.map((dashboard) =>
            <DashboardPaper>
                {dashboard.title} {dashboard.subtitle} {dashboard.description}
                <Grid
                    container
                    direction="row"

                    alignItems="flex-start"
                >
                    {dashboard.chartInfos.map(chart => (
                        <TotalSummary title={chart.name} color='secondary' total={chart.value}/>
                    ))
                    }
                </Grid>
            </DashboardPaper>
        )
    ;

    function handleChangeInstituteMeasure() {
        getInstituteMeasures();
    }

    useEffect(() => {
        getDashboards();
        getInstitutes();
        getInstituteMeasures();
    }, []);

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
            >
                {dashboardItems}
            </Grid>

            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                  aria-controls="panel1-content"
                                  id="panel1-header">
                    <Typography>ÖLÇÜMLER</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ListAlert listItems={instituteMeasures}></ListAlert>
                    <InstituteMeasureList instituteMeasures={instituteMeasures}/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2-content"
                    id="panel2-header">
                    <Typography>KURUMLAR</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {listItems}
                </AccordionDetails>
            </Accordion>

        </div>
    );
}

export default InstituteDashboard;
