import axios from "./index";

class DashboardApi {
    static get = () => {
        return axios.get(`${base}`);
    };
}

let base = "/api/dashboards";

export default DashboardApi;
