import React, {useEffect, useState} from 'react';
import {AccountProfile} from "../component/account/AccountProfile";
import {AccountProfileDetails} from "../component/account/AccountProfileDetails";
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import CurrentUserApi from "../api/CurrentUser";
import {Link} from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import PasswordIcon from "@mui/icons-material/Https";
import CachedIcon from '@mui/icons-material/Cached';
import CacheApi from "../api/Cahce";
import {useSnackbar} from "notistack";

const Account = () => {
    const {enqueueSnackbar} = useSnackbar();

    const [userInfo, setUserInfo] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        city: '',
        country: '',
        userBaseRole: 'ROLE_USER'
    });

    const getUserInfo = () => {
        CurrentUserApi.getMe().then(response => setUserInfo(response.data.data));
    }

    const clearCache = (event) => {
        CacheApi.delete()
            .then(() => {
                enqueueSnackbar('Tüm önbellek başarılı temizlendi', {variant: 'success'});
            })
            .catch(error => {
                if (!error.response || !error.response.data) {
                    enqueueSnackbar('Cache temizlenirken hata ile karşılaşıldı', {variant: 'error'});
                } else {
                    error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                }
            });
    };

    useEffect(() => {
        getUserInfo()
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Typography
                    sx={{mb: 3}}
                    variant="h4"
                >
                    Hesabım
                </Typography>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xs={12}
                    >
                        <AccountProfile userInfo={userInfo}/>
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        md={6}
                        xs={12}
                    >
                        <AccountProfileDetails userInfo={userInfo}/>
                    </Grid>
                </Grid>

                {(userInfo?.userBaseRole === 'ROLE_ADMIN' || userInfo?.userBaseRole === 'ROLE_SUPER_ADMIN' || userInfo?.userBaseRole === 'ROLE_TRAINER') &&
                    <Link to={`/users`}>
                        <Button variant="contained" startIcon={<PeopleIcon/>}>Kullanıcı işlemleri</Button>
                    </Link>}

                <Link to={`/change-password`}>
                    <Button variant="outlined" startIcon={<PasswordIcon/>}>Şifre Değiştirme</Button>
                </Link>

                {(userInfo?.userBaseRole === 'ROLE_ADMIN' || userInfo?.userBaseRole === 'ROLE_SUPER_ADMIN') &&
                    <Button variant="outlined" startIcon={<CachedIcon/>} onClick={event => clearCache(event)}>Önbellek Temizle</Button>
                }

            </Container>
        </Box>
    );
};

export default Account;
