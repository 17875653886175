import React, {useEffect, useState} from 'react';
import UsersApi from "../../api/Users";
import ListAlert from "../ListAlert";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserEdit from "./UserEdit";
import UserPassword from "./UserPassword";
import PersonAdd from "@mui/icons-material/PersonAdd";
import PasswordIcon from "@mui/icons-material/Https";
import Tooltip from "@mui/material/Tooltip";

const UserList = () => {

    const [users, setUsers] = useState([]);

    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);

    const getUsers = () => {
        UsersApi.getUsers().then(response => setUsers(response.data.data));
    }

    useEffect(() => {
        getUsers();
    }, []);


    const groupedUsers = users.reduce((acc, user) => {
        const key = user.institute? user.institute.name:'Eğitimciler';
        acc[key] = acc[key] || [];
        acc[key].push(user);
        return acc;
    }, {});

    const handleClickUserOpen = (selectedUser) => {
        setSelectedUser(selectedUser);
        setOpenUserDialog(true);
    };

    const closeUserDialog = () => {
        setOpenUserDialog(false);
        getUsers();
    };

    const handleClickPasswordOpen = (selectedUser) => {
        setSelectedUser(selectedUser);
        setOpenPasswordDialog(true);
    };

    const closePasswordDialog = () => {
        setOpenPasswordDialog(false);
    };

    return (
        <div>
            <h3>
                <Grid container direction="column-reverse" alignItems="center" alignContent="end">
                    <Grid item style={{marginLeft: 'auto'}}>
                    </Grid>
                </Grid>
            </h3>

            <ListAlert listItems={users}></ListAlert>
            {Object.entries(groupedUsers).map(([key, userList], index) => (
                <Accordion defaultExpanded={index === 0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel{key.code}-content"
                                      id="panel{key.code}-header">
                        <Typography>{key}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tip</TableCell>
                                        <TableCell align="right">Adı</TableCell>
                                        <TableCell align="right">Soyadı</TableCell>
                                        <TableCell align="right">Email</TableCell>
                                        <TableCell align="right">Kullanıcı</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userList.map((user) => (
                                        <TableRow
                                            key={user.name}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{user.userBaseRole}</TableCell>
                                            <TableCell align="right">{user.name}</TableCell>
                                            <TableCell align="right">{user.surname}</TableCell>
                                            <TableCell align="right">{user.email}</TableCell>
                                            <TableCell align="right">{user.user?.username} &nbsp;

                                            {(!user.user && user.email) &&
                                                <Tooltip title="Kullanıcı Ekle"> <Button variant="contained"  onClick={() => handleClickUserOpen(user)} startIcon={<PersonAdd/>}></Button></Tooltip>
                                            }
                                            {user.user &&
                                                <Tooltip title="Şifre Değiştirme"> <Button variant="outlined"  onClick={() => handleClickPasswordOpen(user)} startIcon={<PasswordIcon/>}></Button></Tooltip>
                                            }
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            ))}

            <UserEdit closeDialog={closeUserDialog} isOpen={openUserDialog} userDetail={selectedUser}/>
            <UserPassword closeDialog={closePasswordDialog} isOpen={openPasswordDialog} userDetail={selectedUser}/>
        </div>
    );
};

export default UserList;
