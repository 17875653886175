import React, {useState} from 'react';

import {Link, useNavigate} from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import InstituteMeasureEdit from "../institute-measure/InsituteMeasureEdit";
import AddIcon from "@mui/icons-material/Add";
import {makeStyles} from "@mui/styles";
import {Avatar, Card, CardActions, CardContent, CardHeader, Fab, Grid, IconButton, Menu, MenuItem} from "@mui/material";
import {blueGrey, deepOrange} from "@mui/material/colors";
import MockApi from "../../api/Mock";
import {useSnackbar} from "notistack";
import Tooltip from "@mui/material/Tooltip";


const Institute = (props) => {
    const institute = props.institute;

    const showActionMenu = props.showActionMenu;

    const navigate = useNavigate();

    const {enqueueSnackbar} = useSnackbar();

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        orange: {
            color: theme.palette?.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        blueGrey: {
            color: '#fff',
            backgroundColor: blueGrey[500],
        },
    }));

    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const [state, setState] = useState({
        raised: false,
        shadow: 1,
    })

    const getMockStudentData = () => {
        MockApi.getMockStudentData(institute?.id).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Örnek öğrenci hazırlarken  hata oluştu', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });
    }

    const getMockMeasureData = () => {
        MockApi.getMockMeasureData(institute?.id).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Örnek ölçüm hazırlarken  hata oluştu', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });
    }


    const [openInstituteMeasureDialog, setOpenInstituteMeasureDialog] = useState(false);

    const handleClickInstituteMeasureOpen = () => {
        setOpenInstituteMeasureDialog(true);
    };

    const closeInstituteMeasureDialog = () => {
        setOpenInstituteMeasureDialog(false);
        props.onChange();
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Card className="card-content"
              onMouseOver={() => setState({raised: true, shadow: 4})}
              onMouseOut={() => setState({raised: false, shadow: 1})}
              raised={state.raised} zdepth={state.shadow}
        >
            <CardHeader
                avatar={<Avatar aria-label="recipe" className={classes.orange}>
                    {institute?.name.charAt(0).toUpperCase()}
                </Avatar>}
                action={showActionMenu&& <IconButton aria-label="settings">
                    <MoreVertIcon onClick={handleClick}/>
                </IconButton>}

                title={institute?.name}
            />
            {showActionMenu &&
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem><Link to={`/classroom/${institute?.id}`}>Sınıflar</Link></MenuItem>
                    <MenuItem><Link to={`/teacher/${institute?.id}`}>Öğretmenler</Link></MenuItem>
                </Menu>
            }

            <CardContent onClick={() => showActionMenu && navigate(`/institute/${institute?.id}`)}>
                {institute?.description}
                <Grid container direction="row" alignItems="center" alignContent="stretch">
                    <Grid item>
                        <Avatar variant="rounded" alt="Kurum Müdürü" className={classes.blueGrey}>
                            <AssignmentIndIcon/>
                        </Avatar>
                    </Grid>
                    <Grid item>
                        {institute?.managerName} {institute?.managerSurname}
                    </Grid>
                </Grid>

            </CardContent>

            <CardActions>
                <Tooltip title="Kurum Ölçümü Ekle">
                <Fab color="primary" onClick={handleClickInstituteMeasureOpen} aria-label="Ölçüm Ekle"
                     style={{marginLeft: 'auto'}}>
                    <AddIcon/>
                </Fab>
                </Tooltip>

                <InstituteMeasureEdit closeDialog={closeInstituteMeasureDialog} isOpen={openInstituteMeasureDialog} institute={institute}/>
            </CardActions>
        </Card>
    );
};

export default Institute;
