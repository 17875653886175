import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useParams} from "react-router-dom";
import ClassroomList from "../classroom/ClassroomList";
import TeacherList from "../teacher/TeacherList";
import Institute from "./Institute";
import InstitutesApi from "../../api/Institutes";
import InstituteMeasure from "../institute-measure/InstituteMeasure";
import InstituteMeasureApi from "../../api/InstituteMeasures";
import {useSnackbar} from "notistack";

const InstituteView = () => {

    const {instituteId, measureId} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const [institute, setInstitute] = useState([]);
    const [instituteMeasures, setInstituteMeasures] = useState([]);

    const getInstitute = () => {
        InstitutesApi.getById(instituteId).then(response => setInstitute(response.data.data));
    }

    const getMeasures = () => {
        InstituteMeasureApi.getMeasuresByInstituteId(instituteId).then(response => {
            setInstituteMeasures(response.data.data);
        }).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Ölçüm listesi alınırken hata alındı ', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });
    }

    const listItems = instituteMeasures?.map((instituteMeasure) =>
        <InstituteMeasure key={instituteMeasure.id} instituteMeasure={instituteMeasure}
                          showActionButton={true}/>
    );

    function handleChangeInstituteMeasure() {
        getMeasures()
    }

    useEffect(() => {
        getInstitute();
        getMeasures()
    }, []);


    return (
        <div>
            {institute?.id &&
                <Institute institute={institute} onChange={handleChangeInstituteMeasure} showActionMenu={false}/>
            }
            {listItems.length !== 0 &&
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel1-content"
                                      id="panel1-header">
                        <Typography>ÖLÇÜMLER</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <Grid
                            container
                            direction="row"

                            alignItems="flex-start"
                        >
                            {listItems}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                  aria-controls="panel1-content"
                                  id="panel1-header">
                    <Typography>ÖĞRETMENLER</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TeacherList instituteId={instituteId}/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2-content"
                    id="panel2-header">
                    <Typography>SINIFLAR</Typography>
                </AccordionSummary>
                <ClassroomList instituteId={instituteId}/>
            </Accordion>

        </div>
    );
}

export default InstituteView;
