import React, {useState} from 'react';
import {Box, Button, Container, TextField, Typography} from "@mui/material";
import CurrentUserApi from "../api/CurrentUser";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

const ChangePassword = () => {

    const [passwordRequest, setPasswordRequest] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = e => {
        e.preventDefault();
        Object.assign(passwordRequest, currentPassword);
        Object.assign(passwordRequest, newPassword);
        Object.assign(passwordRequest, confirmNewPassword);

        CurrentUserApi.changePassword({currentPassword, newPassword, confirmNewPassword}).then((response) => {
            enqueueSnackbar('Şifre değişikliği yapıldı', {
                variant: 'info'
            });
            navigate('/');
        }).catch(error => {
                if (!error.response || !error.response.data) {
                    enqueueSnackbar('Kayıt da hata ile karşılaşıldı', {variant: 'error'});
                    return;
                }
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }
        )
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Typography component="h1" variant="h5">
                    Şifre Değiştirme
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="currentPassword"
                        label="Şifre"
                        type="password"
                        id="currentPassword"
                        onChange={e => setCurrentPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="Yeni Şifre"
                        type="password"
                        id="newPassword"
                        onChange={e => setNewPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmNewPassword"
                        label="Tekrar Şifre"
                        type="password"
                        id="confirmNewPassword"
                        onChange={e => setConfirmNewPassword(e.target.value)}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Kaydet
                    </Button>
                </form>
            </Container>
        </Box>
    );
}

export default ChangePassword;
