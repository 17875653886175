import React, {useState} from 'react';
import Auth from '../api/Auth';
import {useSnackbar} from 'notistack';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {makeStyles} from "@mui/styles";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CssBaseline,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {Link} from "react-router-dom";


const Login = () => {


    const useStyles = makeStyles((theme) => ({
        root: {
            height: '100vh',
        },
        image: {
            backgroundImage: 'url(/login_page.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundColor:
                theme.palette?.mode === 'light' ? theme.palette?.grey[50] : theme.palette?.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette?.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));

    // create state variables for each input
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = e => {
        e.preventDefault();
        Auth.Login({user, password}).then((response) => {
            enqueueSnackbar('Kullanıcı girişi yapıldı.Lütfen bekleyiniz...', {
                variant: 'info'
            });
            let token = response.data.data.accessToken;
            localStorage.setItem("token", token);
            window.location = '/';
        }).catch(error => {
                if (!error.response) {
                    enqueueSnackbar('Bağlantı sorunu: Lütfen internet bağlantınızı kontrol ediniz ve tekrar deneyiniz', {
                        variant: 'error'
                    });
                } else {
                    enqueueSnackbar('Kullanıcı adı veya şifre hatalı', {
                        variant: 'error'
                    });
                }

            }
        )
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Kullanıcı Girişi
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="user"
                            label="Kullanıcı Adı"
                            name="user"
                            autoComplete="user"
                            autoFocus
                            onChange={e => setUser(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Şifre"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Beni hatırla"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Giriş
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">

                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">

                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            &copy;2025 Softist Software
                        </Box>
                    </form>
                </div>
            </Grid>
        </Grid>
    );

}

export default Login;
