import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {grey} from "@mui/material/colors";
import CurrentUserApi from "../../api/CurrentUser";
import {alpha, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import SearchApi from "../../api/Search";
import {styled, useTheme} from "@mui/styles";
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search'

const pages = [];
const settings = [{name: 'Hesap', link: '/account'},{name: 'Şifre Değiştirme', link: '/change-password'},  {name: 'Dashboard', link: '/'}, {name: 'Çıkış', link: '/logout'}];

function ResponsiveAppBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const [searchText, setSearchText] = useState(null);
    const [searchResult, setSearchResult] = useState(null);

    const theme = useTheme();

    // Hide AppBar on the login page
    const hideAppBarRoutes = ['/login', '/logout', '/errors'];

    const hideBackButtonRoutes = ['/login', '/logout', '/errors','/'];

    const [userInfo, setUserInfo] = useState({
        name: '',
        surname: '',
        email: '',
    });

    const getUserInfo = () => {
       if( !hideAppBarRoutes.includes(location.pathname)) ( CurrentUserApi.getMe().then(response => setUserInfo(response.data.data)));
    }

    useEffect(() => {
        getUserInfo();
    }, []);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (event, link) => {
        setAnchorElNav(null);
        if (link && link !== "backdropClick") {
            navigate(link);
        }
    };

    const handleCloseUserMenu = (event, link) => {
        setAnchorElUser(null);
        if (link && link !== "backdropClick") {
            navigate(link);
        }
    };

    // Search Start
    const handleSearch = (event) => {
        if (event.target.value) {
            handleSearchTextChange(event)
            SearchApi.getSearch(event.target.value).then(response => setSearchResult(response.data.data));
        }
    }

    const Search = styled('div')(({theme}) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({theme}) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({theme}) => ({
        color: 'inherit',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
    }));

    const keypressHandler = (event) => {
        if (event.key === "Enter") {
            handleSearch(event);
        }
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const resetSearchTextChange = () => {
        setSearchText(null);
        setSearchResult(null);
    };

    if (hideAppBarRoutes.includes(location.pathname)) {
        return (
            <AppBar position="static"  style={{ background: '#000000' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src="/logo_sNMTFlcb.webp" width="225px" alt=""/>
                        </Typography>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 0.2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.08rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src="/logo_sNMTFlcb.webp" width="225px" alt=""/>
                        </Typography>

                    </Toolbar>
                </Container>
            </AppBar>
        );
    }

    return (
        <AppBar position="static"  style={{ background: '#000000' }}>

        <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {!hideBackButtonRoutes.includes(location.pathname) &&  <IconButton size="medium" aria-label="Geri" color="inherit" onClick={() => navigate(-1)}>
                        <ArrowLeftIcon/> Geri
                    </IconButton>
                    }

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src="/logo_sNMTFlcb.webp" width="225px" alt=""/>
                    </Typography>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 0.2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.08rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src="/logo_sNMTFlcb.webp" width="225px" alt=""/>
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={event => handleCloseNavMenu(event, page.link)}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Arama…"
                            inputProps={{'aria-label': 'search'}}
                            onKeyDown={event => keypressHandler(event)}
                            onFocus={() => resetSearchTextChange()}
                            onBlur={(event) => handleSearch(event)}
                        />
                    </Search>
                    {searchResult &&
                        <TableContainer component={Paper}
                                        onClick={() => setSearchResult(null)}
                                        sx={{
                                            position: 'fixed',
                                            top: '65px',
                                            zIndex: theme.zIndex.appBar + 1, // AppBar'ın üzerinde olmasını sağlar
                                        }}
                        >
                            <Table sx={{minWidth: 350}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>'{searchText}' arama sonucu</TableCell>
                                    </TableRow>
                                </TableHead>

                                {Object.entries(searchResult.students).map(([key, students], index) => (
                                    <TableBody>
                                        <TableRow
                                            key={key}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        > <TableCell component="th" scope="row"> {key} Öğrenci Sonucu</TableCell> </TableRow>
                                        {students.map(student => (
                                            <TableRow
                                                key={student.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            > <TableCell component="th" scope="row">
                                                <Link to={`/classroom/${student.classroom.id}/student`}>
                                                    <li>{student.name} {student.surname} ({student.classroom.name})</li>
                                                </Link>
                                            </TableCell> </TableRow>
                                        ))}
                                    </TableBody>


                                    ))}
                                {Object.entries(searchResult.teachers).map(([key, teachers], index) => (
                                    <TableBody>
                                        <TableRow
                                            key={key}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        > <TableCell component="th" scope="row">{key} Öğretmen Sonucu</TableCell> </TableRow>

                                        {teachers.map(teacher => (
                                            <TableRow
                                                key={teacher.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            > <TableCell component="th" scope="row">
                                                <Link to={`/teacher/${teacher.institute.id}`}>
                                                    <li>{teacher.name} {teacher.surname}</li>
                                                </Link>
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                ))}

                            </Table>
                        </TableContainer>
                    }

                    <Typography> {userInfo?.name} {userInfo?.surname}</Typography>

                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Ayarlar">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar sx={{bgcolor: grey[500]}}>
                                    <AccountBoxIcon/>
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.name} onClick={event => handleCloseUserMenu(event, setting.link)}>
                                    <Typography textAlign="center">{setting.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
