import React, {useContext, useEffect, useState} from 'react';
import Classroom from "./Classroom";
import "./ClassroomList.css";
import ClassroomApi from "../../api/Classrooms";
import {useParams} from "react-router-dom";
import ListAlert from "../ListAlert";
import AddIcon from "@mui/icons-material/Add";
import ClassroomEdit from "./ClassroomEdit";
import {Accordion, AccordionDetails, AccordionSummary, Fab, Grid, Typography} from "@mui/material";
import {SeasonContext} from "../../config/SeasonContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import InstituteMeasureApi from "../../api/InstituteMeasures";

const ClassroomList = () => {

    const {instituteId, measureId} = useParams();

    const [classrooms, setClassrooms] = useState([]);
    const [instituteMeasure, setInstituteMeasure] = useState(null);

    const [openClassroomDialog, setOpenClassroomDialog] = useState(false);

    const season = useContext(SeasonContext);

    const handleClickClassroomOpen = () => {
        setOpenClassroomDialog(true);
    };

    const closeClassroomDialog = () => {
        setOpenClassroomDialog(false);
        getClassrooms();
    };

    const getClassrooms = () => {
        ClassroomApi.getByInstituteIdAndSeasonId(instituteId, season?.id).then(response => setClassrooms(response.data.data));
    }

    const getMeasure = () => {
        InstituteMeasureApi.getById(measureId).then(response => setInstituteMeasure(response.data.data));
    }

    useEffect(() => {
        if (measureId){
            getMeasure();
        }
        getClassrooms();
    }, []);

    const groupedClassroom = classrooms.reduce((acc, classroom) => {
        const key = classroom.classLevel.name
        acc[key] = acc[key] || [];
        acc[key].push(classroom);
        return acc;
    }, {});

    return (
        <div>
            <h3>
                <Grid container direction="column-reverse" alignItems="center" alignContent="end">
                    <Grid item style={{marginLeft: 'auto'}}>
                        <Tooltip title="Sınıf Ekle">
                        <Fab color="primary" onClick={handleClickClassroomOpen} aria-label="Sınıf Ekle">
                            <AddIcon/>
                        </Fab>
                        </Tooltip>
                    </Grid>
                </Grid>
            </h3>

            <ListAlert listItems={classrooms}></ListAlert>

            {Object.entries(groupedClassroom).map(([key, classroomList], index) => (
                <Accordion defaultExpanded={index === 0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                      aria-controls="panel{key.code}-content"
                                      id="panel{key.code}-header">
                        <Typography>{key}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            {classroomList.map(classroom => (
                                <Classroom key={classroom.id} classroom={classroom} instituteMeasure={instituteMeasure}
                                           showActionButton={true}/>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}



            <ClassroomEdit closeDialog={closeClassroomDialog} isOpen={openClassroomDialog} instituteId={instituteId}/>
        </div>
    );
};

export default ClassroomList;
