import {useEffect} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {makeStyles} from "@mui/styles";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {useSnackbar} from "notistack";
import {useForm} from "react-hook-form";
import UsersApi from "../../api/Users";

const UserPassword = ({closeDialog, isOpen, userDetail}) => {

    const useStyles = makeStyles((theme) => ({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }));
    const isAddMode = !userDetail?.user?.id;

    const {register, handleSubmit, reset, formState: {errors}} = useForm();
    const classes = useStyles();

    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => {
        reset();
        closeDialog();
    };

    useEffect(() => {

    }, [userDetail]);

    const onSubmit = (data) => {

        if (userDetail.userBaseRole === 'ROLE_TRAINER') {
            UsersApi.updateTrainerUser(userDetail.trainer?.id, data)
                .then(() => {
                    enqueueSnackbar('Eğitimci güncellendi', {variant: 'success'});
                    handleClose();
                })
                .catch(error => {
                    if (!error.response || !error.response.data) {
                        enqueueSnackbar('Kullanıcı güncelemede hata ile karşılaşıldı', {variant: 'error'});
                    } else {
                        error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                    }
                });
        }

        if (userDetail.userBaseRole === 'ROLE_MANAGER') {
            UsersApi.updateManagerUser(userDetail.institute.id, data)
                .then(() => {
                    enqueueSnackbar('Yönetici güncellendi', {variant: 'success'});
                    handleClose();
                })
                .catch(error => {
                    if (!error.response || !error.response.data) {
                        enqueueSnackbar('Kullanıcı güncelemede hata ile karşılaşıldı', {variant: 'error'});
                    } else {
                        error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                    }

                });
        }

        if (userDetail.userBaseRole === 'ROLE_TEACHER') {
            UsersApi.updateTeacherUser(userDetail.teacher.id, data)
                .then(() => {
                    enqueueSnackbar('Öğretmen güncellendi', {variant: 'success'});
                    handleClose();
                })
                .catch(error => {
                    if (!error.response || !error.response.data) {
                        enqueueSnackbar('Kullanıcı güncelemede hata ile karşılaşıldı', {variant: 'error'});
                    } else {
                        error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                    }

                });
        }

        if (userDetail.userBaseRole === 'ROLE_STUDENT') {
            UsersApi.updateStudentUser(userDetail.student.id, data)
                .then(() => {
                    enqueueSnackbar('Öğrenci güncellendi', {variant: 'success'});
                    handleClose();
                })
                .catch(error => {
                    if (!error.response || !error.response.data) {
                        enqueueSnackbar('Kullanıcı güncelemede hata ile karşılaşıldı', {variant: 'error'});
                    } else {
                        error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                    }
                });
        }

    };

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <AppBar className={classes.appBar}  style={{ background: '#1f2e2b' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Şifre Değiştirme
                            - {userDetail.user?.name} {userDetail.user?.surname} ({userDetail.user?.username})
                        </Typography>
                        <Button autoFocus variant="contained" onClick={handleSubmit(onSubmit)}>
                            Kaydet
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <TextField autoFocus margin="dense" required={true} id="password" label="Şifre" type="password" {...register("password")}
                               fullWidth/>
                    <TextField margin="dense" required={true} id="verifyPassword" label="Tekrar Şİfre"
                               type="password" {...register("verifyPassword")}
                               fullWidth/>

                </DialogContent>
            </form>
        </Dialog>
    );


};

export default UserPassword;

