import axios from "./index";

class UsersApi {
    static getUsers = (data) => {
        return axios.get(`${base}`, data);
    };

    static updateTrainerUser = (trainerId, data) => {
        return axios.patch(`${base}/trainers/${trainerId}`, data);
    };

    static getManagerUser = (instituteId) => {
        return axios.get(`${base}/managers/${instituteId}`);
    };

    static updateManagerUser = (instituteId, data) => {
        return axios.patch(`${base}/managers/${instituteId}`, data);
    };

    static getTeacherUser = (teacherId) => {
        return axios.get(`${base}/teachers/${teacherId}`);
    };

    static updateTeacherUser = (teacherId, data) => {
        return axios.patch(`${base}/teachers/${teacherId}`, data);
    };

    static getStudentUser = (studentId) => {
        return axios.post(`${base}/students/${studentId}`);
    };

    static updateStudentUser = (studentId, data) => {
        return axios.patch(`${base}/students/${studentId}`, data);
    };

}

let base = "/api/users";

export default UsersApi;
