import axios from "./index";

class CurrentUserApi {
    static getMe = (data) => {
        return axios.get(`${base}/me`, data);
    };

    static changePassword = (data) => {
        return axios.patch(`${base}/me`, data);
    };

}

let base = "/api/user";

export default CurrentUserApi;
