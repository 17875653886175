import axios from "./index";

class AuthApi {
    static Login = (data) => {
        return axios.post(`${base}/login`, data);
    };

    static Register = (data) => {
        return axios.post(`${base}/singup`, data);
    };

    static Logout = (data) => {
        return axios.post(`${base}/logout`, data);
    };
}

let base = "/api/auth";

export default AuthApi;
