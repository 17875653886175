import axios from "./index";

class CacheApi {
    static get = () => {
        return axios.get(`${base}`);
    };

    static delete = () => {
        return axios.delete(`${base}`);
    };

    static deleteByCacheName = (cahceName) => {
        return axios.get(`${base}/${cahceName}`);
    };

}

let base = "/api/caches";

export default CacheApi;
